import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatModule } from './mat.module';
import { PromptComponent } from '../includes/utilities/popups/prompt/prompt.component';
import { CustomSnackBarComponent } from '../includes/utilities/custom-snack-bar/custom-snack-bar.component';
import { NoDataModule } from '../includes/utilities/no-data/no-data.module';

@NgModule({
  declarations: [
    PromptComponent,
    CustomSnackBarComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatModule,
    NoDataModule,
    RouterModule,
  ],
  exports: [
    HttpClientModule,
    RouterModule,
    MatModule,
    PromptComponent,
    NoDataModule,
    CustomSnackBarComponent
  ],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DesignUtilitiesModule {
}
