<header>
    <mat-toolbar color="primary">
        <button mat-icon-button routerLink="/">
          <mat-icon>menu</mat-icon>
        </button>
        <span>Digital Menu</span>
        <span class="spacer"></span>
        <button mat-button routerLink="/">Home</button>
        
        <app-theme-switch></app-theme-switch>
        

        <!-- <button mat-icon-button class="favorite-icon" >
          <mat-icon>favorite</mat-icon>
        </button>
        
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>person</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item >
            <mat-icon>person</mat-icon>
            <span>Profile</span>
          </button>
          <button mat-menu-item>
            <mat-icon>vpn_key</mat-icon>
            <span>Change Password</span>
          </button>
          <button mat-menu-item >
            <mat-icon>power_settings_new</mat-icon>
            <span>Log Out</span>
          </button>
      </mat-menu> -->
    </mat-toolbar>
</header>