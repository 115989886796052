import { Component, HostBinding, OnInit } from '@angular/core';
import { HandleSubscriptionsComponent } from './appDirectives/handle-subscriptions.directive';
import { ThemeService } from './appServices/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends HandleSubscriptionsComponent implements OnInit {

  @HostBinding('class') theme:any;

  constructor(private _themeService: ThemeService,
    public overlayContainer: OverlayContainer
    ){
    super();
    this.subscribeTheme();
  }

  ngOnInit(): void {
  }


  /**
   * Subscribe Theme
   */
  subscribeTheme(){
    this._themeService.theme.subscribe(res=>{
      this.theme = res;
      this._themeService.setOverlayContainerTheme(res);
    })
  }

  
}



