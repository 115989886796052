import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataComponent } from './no-data.component';
import { MatModule } from 'src/app/appModules/mat.module';



@NgModule({
  declarations: [NoDataComponent],
  imports: [
    CommonModule,
    MatModule
  ],
  exports:[NoDataComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NoDataModule { }
