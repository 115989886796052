import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenentContextService {
  tenant = new BehaviorSubject<string|null>(null);
  // tenant = new BehaviorSubject('');
  domain = new BehaviorSubject(null);
  constructor(
  ) {
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    const tenantName = this.tenant.value;
    if (tenantName) {
      return headers.append('tenant-name', tenantName);
    }
    return headers;
  }
}
