import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/appServices/theme.service';

@Component({
  selector: 'app-theme-switch',
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss']
})
export class ThemeSwitchComponent implements OnInit {

  darkTheme!:boolean;

  constructor(
    private _themeService: ThemeService,
    private _overlayContainer: OverlayContainer) { }

  ngOnInit(): void {
    this.subscribeTheme();
  }

  /**
   * Subscribing Theme
   */
  subscribeTheme(){
    this.getStoredTheme();
    this._themeService.theme.subscribe(res=>{
      if(res==='dark-theme'){
        this.darkTheme = true;
        this._overlayContainer.getContainerElement().classList.add('dark-theme');
      }else{
        this.darkTheme = false;
      }
    })
  }


  /**
   * Setting Theme
   */
  setTheme(){
    this.darkTheme = !this.darkTheme;

    if(this.darkTheme){
      this._overlayContainer.getContainerElement().classList.replace('light-theme', 'dark-theme');
      this._themeService.theme.next('dark-theme');
    }else{
      this._overlayContainer.getContainerElement().classList.replace('dark-theme', 'light-theme');
      this._themeService.theme.next('light-theme');
    }
    this.storeTheme();
  }


  /**
   * Store theme to localstorage
   */
  storeTheme(){
    localStorage.setItem('theme', this.darkTheme?'Dark': 'Light');
  }


  /**
   * Get Stored theme from localstorage
   */
  getStoredTheme(){
    localStorage.getItem('theme')==='Dark' 
    ? 
    this._themeService.theme.next('dark-theme') 
    : 
    this._themeService.theme.next('light-theme')
  }

}
