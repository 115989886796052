<footer>
    <mat-toolbar>
        <span class="copyright">&copy; 2023, 
          <span class="brand">
            Digital Menu
          </span>
          All rights reserved
        </span>
        <button mat-button class="ml-auto">
          Privacy Policy
        </button>
        <button mat-button routerLink="about">
            Terms of Use
        </button>
      </mat-toolbar>
</footer>