import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  theme = new BehaviorSubject('dark-theme');
  
  constructor(
    private overlayContainer:OverlayContainer
  ) { }

  /**
   * Set overlay container theme
   * @param theme 
   */
  setOverlayContainerTheme(theme: string) {
    const containerElement = this.overlayContainer.getContainerElement();
  
    if (containerElement.classList.contains('light-theme') && theme === 'dark-theme') {
      containerElement.classList.replace('light-theme', 'dark-theme');
    } else if (containerElement.classList.contains('dark-theme') && theme === 'light-theme') {
      containerElement.classList.replace('dark-theme', 'light-theme');
    } else if (!containerElement.classList.contains('dark-theme') && !containerElement.classList.contains('light-theme')) {
      containerElement.classList.add(theme);
    }
  }
}
